@font-face {
    font-family: "FZLTH";
    src: url("public/FZLTHJW_0.TTF");
}

/* ---------------- 全局 Start ---------------- */
code {
    font-family: source-code-pro,
    Menlo,
    Monaco,
    Consolas,
    'Courier New',
    monospace;
}

/*修正 antd 4.20 版本的样式缺失问题*/
/*ol, ul, dl {*1/
/*    margin: 0!important;*/
/*    padding:0;*/
/*}*/
#root {
    width: 100%;
    height: 100%;
    font-family: FZLTH;
    overflow: hidden;
}

/* ---------------- 全局 End ---------------- */
.site-tree-search-value {
    color: #f50;
}

/*阴影*/
.shadow {
    box-shadow: 0px 1px 2px -2px rgba(0, 0, 0, 0.16), 0px 3px 6px 0px rgba(0, 0, 0, 0.12), 0px 5px 12px 4px rgba(0, 0, 0, 0.09);
}

/* ---------------- 边框 Start ---------------- */
/* 全边框 */
.border {
    border: 1px solid #f0f0f0;
}

/* 上边框 */
.border-top {
    border-top: 1px solid #f0f0f0;
}

/* 下边框 */
.border-bottom {
    border-bottom: 1px solid #f0f0f0;
}

/* 左边框 */
.border-left {
    border-left: 1px solid #f0f0f0;
}

/* 右边框 */
.border-right {
    border-right: 1px solid #f0f0f0;
}

/* ---------------- 边框 End ---------------- */

/* ---------------- 背景色 Start ---------------- */
/* 灰色 */
.bg-grey {
    background: #f0f2f5;
}

/* 亮灰色 */
.bg-grey-light {
    background: #fafafa;
}

/* 白色 */
.bg-white {
    background: #ffffff;
}

/* ---------------- 背景色 End ---------------- */

/* ---------------- 文本 Start ---------------- */
/* 次标题 */
.secondary-title {
    font-size: 8px !important;
}

.text-color-primary {
    color: #1890ff;
}

/* ----------------  文本 End ---------------- */

/* ---------------- 标准搜索列表页布局 Start ---------------- */
.list-container {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.list-container .list-header {
    overflow: hidden;
    padding: 6px 12px;
    background-color: #e6f7ff;
    flex-shrink: 0;
}

.list-container .list-header .ant-breadcrumb {
    font-weight: bold;
}

.list-container .list-content {
    overflow: hidden;
    padding: 4px;
    background: #f0f2f5;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.list-container .list-content .list-card {
    overflow: hidden;
    padding: 12px;
    background: #fff;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.list-container .list-content .list-card.top {
    margin-top: 4px;
}

/* ---------------- 标准搜索列表页布局 End ---------------- */

/* ---------------- 自定填充卡片 Start ---------------- */
.fill-card {
    padding: 12px;
    background: #fff;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.fill-card.top {
    margin-top: 4px;
}

/* ---------------- 自定填充卡片 End ---------------- */

/* ---------------- Ant-Card辅助样式 Start ---------------- */
/* 卡片顶部外边距 */
.ant-card.top {
    margin-top: 4px;
}

/* ---------------- Ant-Card辅助样式 End ---------------- */

/* ---------------- Ant-Modal辅助样式 Start ---------------- */
/* 弹窗标题加粗 */
.ant-modal-title {
    font-weight: bold !important;
}

/* ---------------- Ant-Modal辅助样式 End ---------------- */

/* ---------------- 外边距 Start ---------------- */
.marginTop {
    margin-top: 4px;
}

.marginLeft {
    margin-left: 4px;
}

/* ---------------- 外边距 End ---------------- */

/* ---------------- 滚动条 Start ---------------- */
.hidden-overflow {
    overflow: hidden;
}

.auto-overflow-x {
    overflow-y: hidden;
    overflow-x: auto;
}

.auto-overflow-y {
    overflow-y: auto;
    overflow-x: hidden;
}

.auto-overflow {
    overflow: auto;
}

.auto-overflow::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

.auto-overflow-x::-webkit-scrollbar {
    height: 6px;
}

.auto-overflow-y::-webkit-scrollbar {
    width: 6px;
}

.auto-overflow::-webkit-scrollbar-thumb,
.auto-overflow-x::-webkit-scrollbar-thumb,
.auto-overflow-y::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: rgba(0, 0, 0, 0.2);
}

.auto-overflow::-webkit-scrollbar-track,
.auto-overflow-x::-webkit-scrollbar-track,
.auto-overflow-y::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 0;
    background: #fafafa;
}

/* ---------------- 滚动条 End ---------------- */

/* ---------------- 表格辅助样式 Start ---------------- */
/*表格编辑组件样式:指定背景色*/
td.table-edit-cell {
    /* background: #efff23; */
    background: #FAFFEE !important;
}

th.table-src-cell {
    background-color: #5998ff !important;
}

th.table-pay-cell {
    background-color: #f6893a !important;
}

/*表格组件 - 表格配置 - 选择客户 Popover*/
.popover-overlay-inner-no-padding .ant-popover-inner-content {
    padding: 0 !important;
}

/* ---------------- 表格辅助样式 End ---------------- */

/* ---------------- Spin 样式 Start ---------------- */
.fill-spin .ant-spin-nested-loading, .fill-spin .ant-spin-nested-loading .ant-spin-container {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    flex-grow: 1;
}

/* ---------------- Spin 样式 End ---------------- */

.main-menu-sub-menu .ant-menu-item:hover,
.main-menu-sub-menu .ant-menu-submenu-title:hover {
    background-color: #e6f7ff;
}

.navigation {
    display: flex;
    justify-content: space-between;
    background-color: #e6f7ff !important;
    border-top: 1px solid #f0f0f0 !important;
    border-bottom: 1px solid #f0f0f0 !important;
    overflow: hidden;
    flex-shrink: 0;
    padding: 6px 12px !important;
}

.components-table-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 0 12px 12px 12px;
    overflow: hidden;
}

.components-table-container-all {
    flex-grow: 1;
    padding: 12px 12px 12px 12px;
    overflow: hidden;
}

.draggable-modal-wrap .ant-modal-header {
    padding: 0
}

.ant-checkbox-disabled + span {
    color: unset !important;
}

.anticon .anticon-dashboard {
    margin-right: 4px;
}
